const useShowRefresh = () => {
  const showRefresh = ref(false)
  const router = useRouter()

  watch(
    () => router.currentRoute.value,
    route => {
      showRefresh.value = route.path === '/'
    },
  )

  onMounted(() => {
    showRefresh.value = router.currentRoute.value.path === '/'
  })
  return {
    showRefresh,
  }
}

export default useShowRefresh
