import { name } from '@/../package.json'
import { defaultNewsList } from '@/utils'

const key = `${name}_main_data`
export const useMainStore = defineStore(
  key,
  () => {
    // 时间
    const timeData = ref<IMainStoreState['timeData']>(null)
    // 主题
    const siteTheme = ref<IMainStoreState['siteTheme']>('light')
    const siteThemeAuto = ref<IMainStoreState['siteThemeAuto']>(true)
    // 当前新闻列表
    const newsList = ref<IMainStoreState['newsList']>(defaultNewsList)
    // 全局配置
    const linkOpenType = ref<IMainStoreState['linkOpenType']>('open')
    const headerFixed = ref<IMainStoreState['headerFixed']>(false)
    const listFontSize = ref<IMainStoreState['listFontSize']>(16)

    const getVisibleNewsList = computed(() => {
      return newsList.value.filter(x => x.show) || []
    })

    function setSiteTheme(val: IMainStoreState['siteTheme']) {
      window.$message.info(
        `已切换至${val === 'dark' ? '深色模式' : '浅色模式'}`,
        {
          showIcon: false,
        },
      )
      siteTheme.value = val
      siteThemeAuto.value = false
    }

    return {
      siteTheme,
      siteThemeAuto,
      newsList,
      linkOpenType,
      headerFixed,
      timeData,
      listFontSize,
      setSiteTheme,
      getVisibleNewsList,
    }
  },
  {
    persist: [
      {
        storage: localStorage,
        pick: [
          'siteTheme',
          'siteThemeAuto',
          'newsList',
          'linkOpenType',
          'headerFixed',
          'listFontSize',
        ],
      },
    ],
  },
)

nextTick(() => {
  const mainStore = useMainStore()
  mainStore.$subscribe((mutation, state) => {
    useLocalStorage(key, state)
  })
})
