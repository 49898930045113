import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
    },
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/list',
    name: 'list',
    meta: {
      title: '新闻列表',
    },
    component: () => import('@/views/list/index.vue'),
  },
  {
    path: '/setting',
    name: 'setting',
    meta: {
      title: '全局设置',
    },
    component: () => import('@/views/setting/index.vue'),
  },
  // 403
  {
    path: '/403',
    name: '403',
    meta: {
      title: '403',
    },
    component: () => import('@/views/exception/403.vue'),
  },
  // 404
  {
    path: '/404',
    name: '404',
    meta: {
      title: '404',
    },
    component: () => import('@/views/exception/404.vue'),
  },
  // 500
  {
    path: '/500',
    name: '500',
    meta: {
      title: '500',
    },
    component: () => import('@/views/exception/500.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
  },
]

export default routes
