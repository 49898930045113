<script lang="ts" setup>
import { author, description, github as githubUrl } from '@/../package.json'
import { useMainStore } from '@/store'
import { openLink } from '@/utils'
const mainStore = useMainStore()

const icpContent = computed(() => {
  return import.meta.env.VITE_APP_ICP || ''
})
</script>

<template>
  <footer class="h-footer">
    <div class="copyright">
      <n-text class="description" v-html="description" />
      <n-text
        class="author"
        :depth="3"
        v-html="author.name"
        @click="openLink(githubUrl, mainStore.linkOpenType)"
      />
    </div>
    <n-text
      v-if="icpContent"
      :depth="3"
      class="icp"
      v-html="icpContent"
      @click="openLink('https://beian.miit.gov.cn/', mainStore.linkOpenType)"
    />
  </footer>
</template>

<style lang="scss" scoped>
.h-footer {
  height: 100px;
  padding: 0 5vw;
  max-width: 1800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .description {
    &::after {
      content: '@ Copyright By';
      margin: 0 6px;
    }
  }

  .author {
    transition: color 0.3s;
    cursor: pointer;

    &:hover {
      color: var(--n-code-text-color);
    }
  }

  .icp {
    padding-top: 4px;
    font-size: 13px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: var(--n-code-text-color);
    }
  }
}
</style>
