<script lang="ts" setup>
import { useMainStore } from '@/store'
import HHeader from '@/components/h-header/index.vue'
import HFooter from '@/components/h-footer/index.vue'
import HProvider from '@/components/h-provider/index.vue'

const mainStore = useMainStore()

// 是否显示header
const showHeader = ref(false)

const onBackTop = (val: boolean) => {
  showHeader.value = val
}
</script>

<template>
  <h-provider>
    <n-layout
      embedded
      :native-scrollbar="false"
      :class="mainStore.headerFixed ? 'fixed' : ''"
    >
      <n-back-top :visibility-height="2" @update:show="onBackTop" />
      <h-header :class="showHeader ? 'show-header' : null" />
      <main>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <transition name="scale" mode="out-in">
              <component :is="Component" />
            </transition>
          </keep-alive>
        </router-view>
      </main>
      <h-footer />
    </n-layout>
  </h-provider>
</template>

<style lang="scss" scoped>
.n-layout {
  height: 100%;

  main {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    min-height: calc(100vh - 218px);
    padding: 0 5vw;
  }

  &.fixed {
    main {
      min-height: calc(100vh - 100px);
      // padding: 118px 5vw 0 5vw;
    }

    .show-header {
      position: absolute;
      top: 0;
      left: 0;
      height: 70px;
      border-bottom: 2px solid var(--n-border-color);
      background-color: var(--n-color);
      padding: 0 5vw;
      :deep(section) {
        .logo {
          img {
            width: 40px;
            height: 40px;
          }
          .name {
            span {
              &:nth-of-type(1) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
