<script lang="ts" setup>
import { zhCN, dateZhCN, darkTheme, lightTheme } from 'naive-ui'
import type { GlobalTheme } from 'naive-ui'
import { useMainStore } from '@/store'

const mainStore = useMainStore()
const osTheme = useOsTheme()

// 明暗切换
let theme = ref<GlobalTheme | null>()
const onChangeTheme = () => {
  theme.value = mainStore.siteTheme === 'light' ? lightTheme : darkTheme
}

// 监听明暗变化
watch(
  () => mainStore.siteTheme,
  () => {
    onChangeTheme()
  },
)

// 根据系统决定明暗切换
const onOsThemeChange = (val: ('light' | 'dark') | null) => {
  // 开启主题自动切换
  if (mainStore.siteThemeAuto && val) {
    mainStore.siteTheme = val
  }
}

// 监听系统明暗变化
watch(
  () => osTheme.value,
  val => {
    onOsThemeChange(val)
  },
)

// 配置主题色
const themeOverrides = {
  common: {
    primaryColor: '#ea444d',
    primaryColorHover: '#F57B74',
    primaryColorSuppl: '#F57B74',
    primaryColorPressed: '#F64B41',
  },
}

// 挂载 naive 组件的方法
const setupNaiveTools = () => {
  window.$loadingBar = useLoadingBar() // 进度条
  window.$notification = useNotification() // 通知
  window.$message = useMessage() // 信息
  window.$dialog = useDialog() // 对话框
}

const NaiveProviderContent = defineComponent({
  setup() {
    setupNaiveTools()
  },
  render() {
    return h('div')
  },
})

onMounted(() => {
  onChangeTheme()
  onOsThemeChange(osTheme.value)
})
</script>

<template>
  <n-config-provider
    abstract
    inline-theme-disabled
    :locale="zhCN"
    :date-locale="dateZhCN"
    :theme="theme"
    :theme-overrides="themeOverrides"
  >
    <n-loading-bar-provider>
      <n-dialog-provider>
        <n-notification-provider>
          <n-message-provider :max="1">
            <slot></slot>
            <naive-provider-content />
          </n-message-provider>
        </n-notification-provider>
      </n-dialog-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>
