import { getCurrentTime } from '@/utils/getTime'

export function useTimer(updateTimeCallback: (time: ICurrentTime) => void) {
  const timer = ref<number | null>(null)

  onMounted(() => {
    timer.value = window.setInterval(() => {
      updateTimeCallback(getCurrentTime())
    }, 1000)
  })

  onBeforeUnmount(() => {
    if (timer.value !== null) {
      clearInterval(timer.value)
      timer.value = null
    }
  })
}
