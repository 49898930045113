export const openLink = (url: string, openType: 'open' | 'href') => {
  if (openType === 'open') {
    window.open(url, '_blank')
  } else {
    window.location.href = url
  }
}

export const jumpLink = (newsData: INewsData, openType: 'open' | 'href') => {
  const url = window.innerWidth > 680 ? newsData.url : newsData.mobileUrl
  openLink(url, openType)
}

export function getImageUrl(name: string) {
  return new URL(`../assets/logo/${name}.png`, import.meta.url).href
}

export const defaultNewsList: INews[] = [
  { label: '哔哩哔哩', name: 'bilibili', order: 0, show: true },
  { label: '微博', name: 'weibo', order: 1, show: true },
  { label: '抖音', name: 'douyin', order: 2, show: true },
  { label: '知乎', name: 'zhihu', order: 3, show: true },
  { label: '36氪', name: '36kr', order: 4, show: true },
  { label: '百度', name: 'baidu', order: 5, show: true },
  { label: '少数派', name: 'sspai', order: 6, show: true },
  { label: 'IT之家', name: 'ithome', order: 7, show: true },
  { label: '澎湃新闻', name: 'thepaper', order: 8, show: true },
  { label: '今日头条', name: 'toutiao', order: 9, show: true },
  { label: '百度贴吧', name: 'tieba', order: 10, show: true },
  { label: '稀土掘金', name: 'juejin', order: 11, show: true },
  { label: '腾讯新闻', name: 'qq-news', order: 12, show: true },
  { label: '豆瓣电影', name: 'douban-movie', order: 13, show: true },
  { label: '原神', name: 'genshin', order: 14, show: true },
  { label: '崩坏：星穹铁道', name: 'starrail', order: 16, show: true },
  { label: 'LOL', name: 'lol', order: 15, show: true },
  { label: '网易新闻', name: 'netease-news', order: 17, show: true },
  { label: '微信读书', name: 'weread', order: 18, show: true },
  { label: '豆瓣讨论小组', name: 'douban-group', order: 19, show: true },
  { label: 'NGA', name: 'ngabbs', order: 20, show: true },
  { label: 'HelloGitHub', name: 'hellogithub', order: 21, show: true },
  { label: '简书', name: 'jianshu', order: 22, show: true },
  { label: '知乎日报', name: 'zhihu-daily', order: 23, show: true },
]

export const sleep = (timeout: number) =>
  new Promise(resolve => setTimeout(resolve, timeout))
