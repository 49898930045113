<script lang="ts" setup>
import { SunOne, Moon, Refresh, SettingTwo } from '@icon-park/vue-next'
import { useMainStore } from '@/store'
import useShowRefresh from '@/hooks/useShowRefresh'
import { useTimer } from '@/hooks/useTimer'

const Menu = defineAsyncComponent(() => import('./menu.vue'))
const router = useRouter()
const mainStore = useMainStore()

// refresh button
const { showRefresh } = useShowRefresh()

// current time
useTimer(time => {
  mainStore.timeData = time
})
</script>

<template>
  <n-card :bordered="false" class="h-header" content-style="padding: 0">
    <section>
      <div class="logo" @click="router.push('/')">
        <img src="@/assets/images/logo.png" alt="logo" />
        <div class="name">
          <n-text>今日热榜</n-text>
          <n-text :depth="3">汇聚全网热点，热门尽览无余</n-text>
        </div>
      </div>
      <div class="current-time">
        <template v-if="mainStore.timeData">
          <n-text class="time">{{ mainStore.timeData.time.text }}</n-text>
          <n-text class="date" :depth="3">
            {{ mainStore.timeData.lunar.day }}
            {{ mainStore.timeData.time.weekday }}
          </n-text>
        </template>
        <template v-else>
          <n-text class="time">时间获取中...</n-text>
        </template>
      </div>
      <div class="controls">
        <n-space justify="end">
          <n-popover v-if="showRefresh">
            <template #trigger>
              <n-button secondary strong round @click="router.go(0)">
                <template #icon>
                  <n-icon :component="Refresh" />
                </template>
              </n-button>
            </template>
            刷新页面
          </n-popover>
          <n-popover>
            <template #trigger>
              <n-button
                secondary
                strong
                round
                @click="
                  mainStore.setSiteTheme(
                    mainStore.siteTheme === 'light' ? 'dark' : 'light',
                  )
                "
              >
                <template #icon>
                  <n-icon
                    :component="mainStore.siteTheme === 'light' ? Moon : SunOne"
                  />
                </template>
              </n-button>
            </template>
            {{ mainStore.siteTheme === 'light' ? '深色模式' : '浅色模式' }}
          </n-popover>
          <n-popover>
            <template #trigger>
              <n-button secondary strong round @click="router.push('/setting')">
                <template #icon>
                  <n-icon :component="SettingTwo" />
                </template>
              </n-button>
            </template>
            全局设置
          </n-popover>
        </n-space>
      </div>

      <Menu class="menu" />
    </section>
  </n-card>
</template>

<style lang="scss" scoped>
.h-header {
  display: flex;
  width: 100%;
  height: 118px;
  padding: 24px 5vw;
  background: transparent;
  transition: all 0.3s;
  z-index: 2;

  section {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    .logo {
      display: flex;
      cursor: pointer;

      > img {
        width: 50px;
        height: 50px;
        transition: all 0.3s;
      }

      .name {
        display: flex;
        flex-direction: column;
        padding-left: 16px;

        span {
          &:nth-of-type(1) {
            font-size: 20px;
            font-weight: bold;
            transition: all 0.3s;
          }
          &:nth-of-type(2) {
            font-size: 12px;
          }
        }
      }
    }

    .current-time {
      display: flex;
      flex-direction: column;
      align-items: center;

      .date {
        font-size: 12px;
      }
    }

    .menu {
      display: none;
    }

    // 移动端
    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;

      .logo {
        > img {
          width: 40px;
          height: 40px;
        }

        .name {
          span {
            &:nth-of-type(1) {
              font-size: 18px;
            }
          }
        }
      }

      .current-time,
      .controls {
        display: none;
      }

      .menu {
        display: block;
      }
    }
  }
}
</style>
